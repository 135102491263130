.row-custom-accomplishments {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* align-items: inherit; */
}

.row-custom-accomplishments .heading {
  font-size: 25px;
  font-weight: 600;
  margin-top: 73px;
}

.row-custom-accomplishments .col-cards-honors,
.row-custom-accomplishments .col-cards-published-papers,
.row-custom-accomplishments .col-cards-certifications {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.col-cards-honors .custom-card,
.col-cards-certifications .custom-card {
  width: 334px;
}

.row-custom-accomplishments .custom-card {
  height: 334px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  position: relative;
  display: flex;
  margin: 50px auto 0;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 24px;
  transition: all 0.3s;
}

.row-custom-accomplishments .custom-card.hoverable {
  cursor: pointer;
}
.row-custom-accomplishments .custom-card.hoverable:hover {
  transform: translateY(-3px);
}

.row-custom-accomplishments .custom-card.hoverable::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.row-custom-accomplishments .custom-card.hoverable:hover::before {
  opacity: 1;
}

.row-custom-accomplishments .custom-card .desc {
  margin-top: 10px;
}

.row-custom-accomplishments .custom-card .desc p {
  margin-bottom: 0;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .row-custom-accomplishments .heading {
    margin-top: 50px;

  }

  .row-custom-accomplishments .custom-card {
    margin-top: 25px;
    height: 230px;
    width: 180px;
  }

  .row-custom-accomplishments .col-cards-published-papers .custom-card {
    width: 97%;
  }

  .row-custom-accomplishments .custom-card .custom-card-heading h3{
    font-size: 13px;
    margin-bottom: 0;
    line-height: 20px;
  }

  .row-custom-accomplishments .custom-card .desc{
    margin-top: 15px;
  }

  .row-custom-accomplishments .custom-card .desc p{
    font-size: 13px;
  }
}
/* Mobile END */