@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to right, #6a67ce, #fc636b);
  margin: 25px;
  color: #fff;
}

p{
  font-size: 17px;
}

h3{
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
}

strong{
  font-weight: 600;
}

code {
  font-family: 'Montserrat', sans-serif;
}

/* Mobile */
@media screen and (max-width: 1099px) {
   body{
    margin: 5px;
   } 
   p{
    font-size: 15px;
  }
}
/* Mobile END */