.CaseStudy-container {
  padding-top: 95px;
}

.CaseStudy-container .row-custom.custom-cards {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.CaseStudy-container .custom-card {
  width: 334px;
  height: 416px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  position: relative;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding: 26px 24px 42px 24px;
  transition: all 0.3s;
  cursor: pointer;
}

.CaseStudy-container .custom-card:hover {
  transform: translateY(-3px);
}

.CaseStudy-container .custom-card::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.CaseStudy-container .custom-card:hover::before {
  opacity: 1;
}

.CaseStudy-container .custom-card .custom-card-image img {
  width: 287px;
}

.CaseStudy-container .custom-card .subTitle {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
}

.CaseStudy-container .custom-card .custom-card-heading {
  margin-top: 20px;
}

.CaseStudy-container .custom-card .custom-card-heading h3 {
  margin: 0;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .CaseStudy-container {
    padding-top: 50px;
  }

  .CaseStudy-container .row-custom.custom-cards {
    justify-content: space-around
  }

  .CaseStudy-container .custom-card {
    width: 170px;
    height:300px;
    margin-top: 25px;
    padding: 13px 12px 21px 12px;
  }
  .CaseStudy-container .custom-card .custom-card-image img {
    width:148px;
  }
  .CaseStudy-container .custom-card .custom-card-heading {
    margin-top:5px;
  }
  .CaseStudy-container .custom-card .custom-card-heading h3 {
    font-size:15px;
    line-height: 25px;
  }
}
/* Mobile END */