.Menubar-container {
  margin-bottom: 60px;
}

.Menubar-container .navbar-main.show-navbar.background-dark{
    box-shadow: 0px 0px 19px 0 #1c1e22;
    background-color: #212428de;
    backdrop-filter: blur(15px);
}

.Menubar-container > .navbar-main {
  height: 100px;
}
.Menubar-container > .navbar-main > .container {
  max-width: 1500px;
}


.Menubar-container .navbar-collapse .navbar-nav {
  align-items: center;
}

.Menubar-container .menubar-link {
  color: white !important;
  padding-left: 0 !important;
  padding-right: 0px !important;
  margin-right: 35px !important;
}

.Menubar-container .menubar-link:hover {
  color: #ff004f !important;
}

.Menubar-container .menubar-link.connect-wallet-button {
  color: #ff004f !important;
  margin-right: 0 !important;
  height: 56px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 28px;
  text-align: center;
  transition: all 0.3s;
  position: relative;
  text-decoration: none;
  padding: 18px 33px !important;
}

.Menubar-container .menubar-link.connect-wallet-button:hover {
  transform: translateY(-3px);
}

.Menubar-container .menubar-link.connect-wallet-button::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 28px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.Menubar-container .menubar-link.connect-wallet-button:hover::before {
  opacity: 1;
}

.bn-onboard-custom.bn-onboard-modal.svelte-rntogh{
  z-index: 9;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .Menubar-container > .navbar-main {
    height: 66px;
    z-index: 999999;
  }
  .navbar-collapse {
    box-shadow: 0px 0px 19px 0 #1c1e22;
    background-color: #212428f2;
  }
  .Menubar-container .menubar-link {
    margin-right: 0 !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .Menubar-container .navbar-collapse .navbar-nav {
    padding-bottom: 30px;
  }
}
/* Mobile END */