.ring {
    position: fixed;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border: 2px solid #FF004F;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    /* -webkit-transition-duration: 1ms; */
    /* transition-duration: 1ms; */
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    will-change: width, height, transform, border;
    z-index: 999999;
    pointer-events: none;
  }
  
  /* .dot {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #FF004F;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;
  } */

  /* Mobile */
@media screen and (max-width: 1099px) {
  .ring{
    display: none;
  }
}
  /* Mobile END */
