@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to right, #6a67ce, #fc636b);
  margin: 25px;
  color: #fff;
}

p{
  font-size: 17px;
}

h3{
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
}

strong{
  font-weight: 600;
}

code {
  font-family: 'Montserrat', sans-serif;
}

/* Mobile */
@media screen and (max-width: 1099px) {
   body{
    margin: 5px;
   } 
   p{
    font-size: 15px;
  }
}
/* Mobile END */
.App {
  text-align: center;
}

.App-Container{
  background-color: #212428;
}

.grid-1100{
  max-width: 1100px;
  margin: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.text-red {
  color: #ff004f;
}

.row-custom {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.banner-container .row-custom {
  justify-content: space-between;
}

.banner-container .col-text {
  max-width: 478px;
  text-align: left;
}

.title-primary h2 {
  font-size: 40px;
  font-weight: 600;
  margin-top: 14px;
  margin-bottom: 9px;
}

.subTitle {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1.7px;
}

.banner-container .dynamic-text {
  font-size: 33px;
  font-weight: 600;
}

.banner-container .desc {
  margin-top: 29px;
  margin-bottom: 89px;
  line-height: 28px;
}

.banner-container .desc p {
  margin: 0;
}

.row-custom .social-links {
  justify-content: flex-start;
  margin-top: 21px;
}

.row-custom .social-links .col-33 {
  margin-right: 45px;
}

.banner-container .social-button {
  display: inline-block;
  width: 67px;
  height: 67px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  line-height: 75px;
  text-align: center;
  transition: all 0.3s;
  position: relative;
}

.banner-container .social-button:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.banner-container .social-button::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.banner-container .social-button:hover::before {
  opacity: 1;
}
.bannerImage {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
}

.bannerImage::before {
  width: 100%;
  height: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: linear-gradient(to right, #6a67ce, #fc636b) !important;
  position: absolute;
  z-index: -1;
  content: "";
  left: 50%;
  border-radius: 7px;
}

.bannerImageInner {
  padding: 15px;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .banner-container{
    padding: 0 10px;
  }
  .banner-container .row-custom {
    flex-direction: column-reverse;
  }

  .banner-container .col-text{
    text-align: center;
    margin-top: 40px;
  }

  .title-primary h2 {
    font-size: 28px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .subTitle {
    font-size: 13px;
  }

  .banner-container .dynamic-text {
    font-size: 24px;
  }

  .banner-container .desc {
    margin-top: 15px;
    margin-bottom: 25px;
    line-height: 25px;
  }

  .bannerImageInner{
    padding: 5px;
  }

  .bannerImageInner img{
    width: 330px;
  }

  .row-custom .social-links {
    flex-direction: row;
    justify-content: space-evenly;
  }
  .row-custom .social-links .col-33 {
    margin-right: 0;
  }
}
/* Mobile END*/

.Divider-container {
  padding-top: 95px;
}
.Divider-container hr {
  border: 1px solid #121415;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .Divider-container {
    padding-top: 50px;
  }
}
/* Mobile END */

.Skills-container {
  padding-top: 95px;
}

.Skills-container .row-custom.cards {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.Skills-container .custom-card {
  width: 334px;
  height: 334px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  line-height: 75px;
  text-align: center;
  position: relative;
  display: inline-block;
  display: flex;
  margin: 50px auto 0;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 24px;
}

.Skills-container .custom-card .custom-card-image {
  margin-top: 61px;
  line-height: 11px;
}

.Skills-container .custom-card .custom-card-heading {
  margin-top: 31px;
}

.Skills-container .custom-card .custom-card-heading h3 {
  margin: 0;
}

.Skills-container .custom-card .desc p {
  line-height: 28px;
  margin-top: 21px;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .Skills-container {
    padding-top: 50px;
  }
  .Skills-container .row-custom.cards {
    justify-content: space-around;
  }
  .Skills-container .row-custom.cards .custom-card {
    width: 165px;
    height: 285px;
    margin-top: 25px;
  }
  .Skills-container .custom-card .custom-card-image {
      margin-top: 30px
  }
  .Skills-container .custom-card .custom-card-heading{
      margin-top: 18px;
  }
  .Skills-container .custom-card .custom-card-heading h3 {
      font-size: 18px;
  }
  .Skills-container .custom-card .desc{
    padding: 0 12px;
  }
  .Skills-container .custom-card .desc p {
      font-size: 13px;
      margin-bottom: 0px;
      line-height: 23px;
      margin-top: 0.25rem;
  }
}
/* Mobile END */

.Clients-container {
  padding-top: 95px;
}

.Clients-container .row-custom.cards {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.Clients-container .card {
  display: flex;
  justify-content: center;
  width: 187px;
  height: 187px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  position: relative;
  margin-top: 50px;
  align-items: center;
}

/* .Clients-container .card .card-image img{
    width: 101px;
} */

/* Mobile */
@media screen and (max-width: 1099px) {
  .Clients-container {
    padding-top: 50px;
  }

  .Clients-container .row-custom.cards {
    justify-content: space-evenly;
    margin-top: 0;
  }

  .Clients-container .card {
  margin-top: 25px;
  width: 80px;
  height: 80px;
  }

  .Clients-container .card img{
    max-width: 50px;
    max-height: 50px;
  }
}
/* Mobile END */
.fade {
    transition: opacity .15s linear;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 1;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #212428;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none;
}

.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: auto;
    pointer-events: none;
    max-width: 1100px;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border-radius: 5px;
    outline: 0;
    background: linear-gradient(145deg, #1e2024, #23272b);
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    /* border-bottom: 1px solid #dee2e6; */
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-header .btn-close {
    padding: 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
    border-radius: 34px;
    background: linear-gradient(145deg, #1e2024, #23272b);
    box-shadow: 3px 3px 5px #1c1e22, -3px -3px 5px #262a2e;
    background-image: url(/static/media/CloseIcon.c12cbfd7.svg);
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: center;
}

.modal-header .btn-close:hover{
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}

.modal-header .btn-close::before{
    background: linear-gradient(to right bottom, #212428, #16181c);
    border-radius: 34px;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    top: 0;
    left: 0;
    content: '';
}

.modal-header .btn-close::before{
    opacity: 1;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #fff;
    /* background-image: url('../../Images/Common/CloseIcon.svg'); */
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.Project-modal-container .modal-header {
    padding: 25px 25px 10px 25px;
    border: none;
}

.Project-modal-container .modal-body {
    padding: 0 24px 63px 24px;
}

.Project-modal-container .row-custom.modal-row{
    justify-content: space-between;
}

.Project-modal-container .col-text{
    max-width: 550px;
}

.modal-body .heading h3{
    margin: 0;
}

.modal-body .col-text .desc{
    margin-top: 10px;
}

.modal-body .col-text .desc p{
    margin: 0;
    line-height: 28px;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.Project-modal-container .cta-row{
    display: flex;
    justify-content: start;
    align-items: baseline;
}

.Project-modal-container .cta-buttons{
    justify-content: flex-start;
    margin-top: 15px;
    margin-right: 30px;
}

.Project-modal-container .cta-buttons .cta-view-project{
    display: inline-block;
    background: linear-gradient(145deg, #1e2024, #23272b);
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    border-radius: 28px;
    text-align: center;
    transition: all 0.3s;
    position: relative;
    text-decoration: none;
    padding: 18px 45px;
}

.Project-modal-container .cta-buttons .cta-view-project:hover{
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
}

.Project-modal-container .cta-buttons .cta-view-project::before{
    background: linear-gradient(to right bottom, #212428, #16181c);
    width: 100%;
    height: 100%;
    border-radius: 28px;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
    top: 0;
    left: 0;
    content: '';
}

.Project-modal-container .cta-buttons .cta-view-project:hover::before{
    opacity: 1;
}

.Project-modal-container .likes{
    font-size: 17px;;
    display: flex;
}

.Project-modal-container .likes .likes-icon{
    width: 25px;
    margin-right: 12px;
}

/* Mobile */
@media screen and (max-width: 1099px) {
    .modal.fade .modal-dialog {
        width: 100%;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    .Project-modal-container .modal-body {
        padding: 20px 30px 30px;
    }

    .Project-modal-container .row-custom.modal-row {
        flex-direction: column;
    }


    .Project-modal-container .col-text {
        margin-top: 15px;
    }

    .Project-modal-container .row-custom.modal-row .col-image .modalImage img{
        width: 320px;
    }

    .modal-body .heading h3 {
        font-size: 18px;
    }

    .Project-modal-container .cta-buttons .cta-view-project {
        font-size: 15px;
        padding: 14px 40px;
    }
}
/* Mobile END */
.Projects-container {
  padding-top: 95px;
}

.Projects-container .row-custom.custom-cards {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.Projects-container .custom-card {
  width: 334px;
  height: 416px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  position: relative;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding: 26px 24px 42px 24px;
  transition: all 0.3s;
  cursor: pointer;
}

.Projects-container .custom-card:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.Projects-container .custom-card::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.Projects-container .custom-card:hover::before {
  opacity: 1;
}

.Projects-container .custom-card .custom-card-image img {
  width: 287px;
}

.Projects-container .custom-card .subTitle {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
}

.Projects-container .custom-card .custom-card-heading {
  margin-top: 20px;
}

.Projects-container .custom-card .custom-card-heading h3 {
  margin: 0;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .Projects-container {
    padding-top: 50px;
  }

  .Projects-container .row-custom.custom-cards {
    justify-content: space-around
  }

  .Projects-container .custom-card {
    width: 170px;
    height:300px;
    margin-top: 25px;
    padding: 13px 12px 21px 12px;
  }
  .Projects-container .custom-card .custom-card-image img {
    width:148px;
  }
  .Projects-container .custom-card .custom-card-heading {
    margin-top:5px;
  }
  .Projects-container .custom-card .custom-card-heading h3 {
    font-size:15px;
    line-height: 25px;
  }
}
/* Mobile END */
.CaseStudy-container {
  padding-top: 95px;
}

.CaseStudy-container .row-custom.custom-cards {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.CaseStudy-container .custom-card {
  width: 334px;
  height: 416px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  position: relative;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding: 26px 24px 42px 24px;
  transition: all 0.3s;
  cursor: pointer;
}

.CaseStudy-container .custom-card:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.CaseStudy-container .custom-card::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.CaseStudy-container .custom-card:hover::before {
  opacity: 1;
}

.CaseStudy-container .custom-card .custom-card-image img {
  width: 287px;
}

.CaseStudy-container .custom-card .subTitle {
  margin-top: 21px;
  display: flex;
  justify-content: space-between;
}

.CaseStudy-container .custom-card .custom-card-heading {
  margin-top: 20px;
}

.CaseStudy-container .custom-card .custom-card-heading h3 {
  margin: 0;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .CaseStudy-container {
    padding-top: 50px;
  }

  .CaseStudy-container .row-custom.custom-cards {
    justify-content: space-around
  }

  .CaseStudy-container .custom-card {
    width: 170px;
    height:300px;
    margin-top: 25px;
    padding: 13px 12px 21px 12px;
  }
  .CaseStudy-container .custom-card .custom-card-image img {
    width:148px;
  }
  .CaseStudy-container .custom-card .custom-card-heading {
    margin-top:5px;
  }
  .CaseStudy-container .custom-card .custom-card-heading h3 {
    font-size:15px;
    line-height: 25px;
  }
}
/* Mobile END */
.row-custom.custom-tab {
  flex-direction: column;
}
.row-custom.custom-tab .heading {
  font-size: 25px;
  font-weight: 600;
  margin-top: 73px;
}

.row-custom.custom-tab .col-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row-custom.custom-tab .custom-icon {
  width: 209px;
  margin-top: 50px;
}

.row-custom.custom-tab .custom-icon .custom-icon-title {
  margin-top: 22px;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .row-custom.custom-tab .heading {
    margin-top: 50px;
  }
  .row-custom.custom-tab .col-icons {
    justify-content: space-evenly;
  }
  .row-custom.custom-tab .custom-icon {
    width: 80px;
    margin-top: 25px;
  }
  
  .row-custom.custom-tab .custom-icon .custom-icon-image{
    height: 70px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .row-custom.custom-tab .custom-icon .custom-icon-image img{
    max-width: 70px;
  }
  .row-custom.custom-tab .custom-icon .custom-icon-title {
    font-size: 12px;
  }
}
/* Mobile END */
.row-custom-accomplishments {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* align-items: inherit; */
}

.row-custom-accomplishments .heading {
  font-size: 25px;
  font-weight: 600;
  margin-top: 73px;
}

.row-custom-accomplishments .col-cards-honors,
.row-custom-accomplishments .col-cards-published-papers,
.row-custom-accomplishments .col-cards-certifications {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.col-cards-honors .custom-card,
.col-cards-certifications .custom-card {
  width: 334px;
}

.row-custom-accomplishments .custom-card {
  height: 334px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  position: relative;
  display: flex;
  margin: 50px auto 0;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 24px;
  transition: all 0.3s;
}

.row-custom-accomplishments .custom-card.hoverable {
  cursor: pointer;
}
.row-custom-accomplishments .custom-card.hoverable:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.row-custom-accomplishments .custom-card.hoverable::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.row-custom-accomplishments .custom-card.hoverable:hover::before {
  opacity: 1;
}

.row-custom-accomplishments .custom-card .desc {
  margin-top: 10px;
}

.row-custom-accomplishments .custom-card .desc p {
  margin-bottom: 0;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .row-custom-accomplishments .heading {
    margin-top: 50px;

  }

  .row-custom-accomplishments .custom-card {
    margin-top: 25px;
    height: 230px;
    width: 180px;
  }

  .row-custom-accomplishments .col-cards-published-papers .custom-card {
    width: 97%;
  }

  .row-custom-accomplishments .custom-card .custom-card-heading h3{
    font-size: 13px;
    margin-bottom: 0;
    line-height: 20px;
  }

  .row-custom-accomplishments .custom-card .desc{
    margin-top: 15px;
  }

  .row-custom-accomplishments .custom-card .desc p{
    font-size: 13px;
  }
}
/* Mobile END */
.Education-container {
  margin-top: 50px;
}

.vertical-timeline {
  width: 1050px;
}

.custom-vertical-timeline-element {
  margin-bottom: 0px !important;
  margin-top: 0 !important;
}
.custom-vertical-timeline-element:not(:last-child) {
  margin-bottom: 35px !important;
}

.custom-vertical-timeline-element .vertical-timeline-element-content {
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  display: flex;
  align-content: center;
  padding: 24px;
}

.custom-vertical-timeline-element .vertical-timeline-element-icon {
  background-color: #ff004f;
  box-shadow: 0 0 0 4px #1c1b1b, inset 0 2px 0 rgb(0 0 0 / 8%),
    0 3px 0 4px rgb(0 0 0 / 5%);
  /* width: 22px;
    height: 22px; */
}

.custom-vertical-timeline-element
  .vertical-timeline-element-content
  .vertical-timeline-element-content-arrow {
  border-right: 7px solid #1e2024;
}

.custom-vertical-timeline-element
  .vertical-timeline-element-content
  .custom-content-top {
  display: flex;
}

.custom-vertical-timeline-element
  .vertical-timeline-element-content
  .custom-content-top
  .custom-col-text {
  margin-left: 30px;
}

.custom-vertical-timeline-element
  .vertical-timeline-element-content
  .custom-content-top
  .custom-col-text-heading {
  font-size: 25px;
  font-weight: 600;
}

.custom-vertical-timeline-element
  .vertical-timeline-element-content
  .custom-content-top
  .custom-col-text-subheading {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
}

.custom-vertical-timeline-element
  .vertical-timeline-element-content
  .custom-content-top
  .custom-col-text-tertiarytext {
  display: flex;
}

.custom-vertical-timeline-element
  .vertical-timeline-element-content
  .custom-content-top
  .custom-col-text-tertiarytext
  .custom-col-text-tertiarytext-year,
.custom-vertical-timeline-element
  .vertical-timeline-element-content
  .custom-content-top
  .custom-col-text-tertiarytext
  .custom-col-text-tertiarytext-marks {
  font-size: 15px;
  margin-right: 30px;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .vertical-timeline {
    width: 350px;
  }

  .vertical-timeline--animate .vertical-timeline-element-icon {
    width: 20px;
    height: 20px;
    left: 10px;
    top: 12px;
  }

  .vertical-timeline-element-content {
    margin-left: 44px;
  }

  .custom-vertical-timeline-element
    .vertical-timeline-element-content
    .custom-content-top
    .custom-col-text {
    margin-left: 12px;
    text-align: left;
  }

  .custom-vertical-timeline-element
    .vertical-timeline-element-content
    .custom-content-top
    .custom-col-image
    img {
    width: 50px;
  }

  .custom-vertical-timeline-element
    .vertical-timeline-element-content
    .custom-content-top
    .custom-col-text-heading {
    font-size: 15px;
  }

  .custom-vertical-timeline-element
    .vertical-timeline-element-content
    .custom-content-top
    .custom-col-text-subheading {
    font-size: 14px;
    line-height: 19px;
  }

  .custom-vertical-timeline-element
    .vertical-timeline-element-content
    .custom-content-top
    .custom-col-text-tertiarytext {
    flex-direction: column;
  }

  .custom-vertical-timeline-element
    .vertical-timeline-element-content
    .custom-content-top
    .custom-col-text-tertiarytext
    .custom-col-text-tertiarytext-year,
  .custom-vertical-timeline-element
    .vertical-timeline-element-content
    .custom-content-top
    .custom-col-text-tertiarytext
    .custom-col-text-tertiarytext-marks {
    font-size: 14px;
  }
}
/* Mobile END */

.Experience-container {
  margin-top: 50px;
}

.Experience-container .vertical-timeline-element-content {
  display: flex;
  flex-direction: column !important;
}

.custom-vertical-timeline-element .custom-content-bottom {
  margin-top: 24px;
}

.custom-vertical-timeline-element
  .custom-content-bottom
  .custom-content-bottom-list {
  text-align: left;
  font-size: 17px;
}

.custom-vertical-timeline-element
  .custom-content-bottom
  .custom-content-bottom-list
  li::marker {
  color: #ff004f;
}

.vertical-timeline-element-date,
.vertical-timeline-element-date::after {
  display: none !important;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .custom-vertical-timeline-element
    .custom-content-bottom
    .custom-content-bottom-list {
    font-size: 14px;
    padding-left: 1rem;
  }
}
/* Mobile END */

.Resume-container {
  padding-top: 95px;
}

.Resume-container .custom-tabs {
  flex-direction: column;
  margin-top: 50px;
}

.Resume-container .custom-tabs .nav-tabs {
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  position: relative;
  border: none;
}

.Resume-container .custom-tabs .nav-tabs .nav-item .nav-link {
  width: 275px;
  text-decoration: none;
  color: #fff;
  height: 93px;
  transition: all 0.3s;
  border: none;
}
.Resume-container .custom-tabs .nav-tabs .nav-item .nav-link:hover {
  border: none;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.Resume-container .custom-tabs .nav-tabs .nav-item .nav-link.active {
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  color: #ff004f;
  border: none;
}
.Resume-container .custom-tabs .nav-tabs .nav-item .nav-link::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}
.Resume-container .custom-tabs .nav-tabs .nav-item .nav-link:hover::before {
  opacity: 1;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .Resume-container {
    padding-top: 50px;
  }
  .Resume-container .custom-tabs {
    margin-top: 25px;
  }

  .Resume-container .custom-tabs .nav-tabs {
    width: 95%;
  }

  .Resume-container .custom-tabs .nav-tabs .nav-item .nav-link {
    width: 180px;
    font-size: 14px;
  }
}
/* Mobile END */

.Contact-container {
  padding-top: 95px;
}

.Contact-container .desc {
  margin-top: 22px;
}

.Contact-container .desc p {
  margin-bottom: 0;
}

.Contact-container .desc a:hover {
  color: #ff004f;
  cursor: pointer;
}

.Contact-container .contact-form.row-custom {
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.Contact-container .contact-form-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 399px;
  margin-top: 58px;
}
.Contact-container .contact-form-input label {
  margin-bottom: 20px;
}

.Contact-container .contact-form-input input,
.Contact-container .contact-form-input .rw-date-picker {
  width: 100%;
  height: 56px;
}

.Contact-container .contact-form-input .input-name,
.Contact-container .contact-form-input .input-time .rw-widget-input,
.Contact-container .contact-form-input .input-time .rw-popup,
.Contact-container .contact-form-input .input-time .rw-popup .rw-list,
.Contact-container .contact-form-input .input-date .rw-widget-picker,
.Contact-container .contact-form-input .input-email {
  /* background: linear-gradient(145deg, #1e2024, #23272b); */
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  border: 0;
  /* color: #fff; */
}
.Contact-container .contact-form-input .input-name:focus-visible,
.Contact-container .contact-form-input .input-email:focus-visible {
  /* background: linear-gradient(145deg, #1e2024, #23272b); */
  /* box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e; */
  border-radius: 5px;
  outline-width: 0;
}

.Contact-container .contact-form-input .input-name,
.Contact-container .contact-form-input .input-email {
  padding-left: 13px;
}

.Contact-container
  .contact-form-input
  .input-date
  .rw-widget-picker
  #rw_3_input {
  /* color: #fff; */
  color: #000;
  background-color: transparent;
}

.Contact-container
  .contact-form-input
  .input-date
  .rw-widget-picker
  .rw-picker-btn {
  /* background-color: transparent; */
  background: linear-gradient(to right bottom, #212428, #16181c);
  border: none;
}

.Contact-container
  .contact-form-input
  .input-date
  .rw-widget-picker
  .rw-picker-btn:hover {
  background: linear-gradient(to right bottom, #212428, #16181c);
}

.Contact-container
  .contact-form-input
  .input-date
  .rw-widget-picker
  .rw-picker-btn
  svg {
  /* fill: #fff; */
  fill: #ff004f;
}

.Contact-container .contact-form-input .input-date .rw-calendar-popup {
  width: 100%;
}

.Contact-container
  .contact-form-input
  .input-date
  .rw-calendar-popup
  .rw-state-selected {
  background-color: #ff004f;
  border-color: #ff004f;
}

.Contact-container .contact-form-input .input-time .rw-dropdown-list-input {
  align-items: center;
  text-align: left;
}

.Contact-container .contact-form-input .input-time .rw-picker-caret svg {
  fill: #ff004f;
}

.Contact-container .contact-form-input .input-time .rw-popup .rw-list-option {
  /* color: #fff; */
  font-weight: 600;
  color: #000;
  transition: all 0.3s;
  height: 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Contact-container
  .contact-form-input
  .input-time
  .rw-popup
  .rw-list-option:hover {
  border: none;
  /* background: linear-gradient(to right bottom, #212428, #16181c); */
  background-color: #ff004f;
}
.Contact-container
  .contact-form-input
  .input-time
  .rw-popup
  .rw-list-option.rw-state-selected {
  background-color: #ff004f;
}

.Contact-container .contact-form-submit {
  width: 893px;
  margin-top: 68px;
}
.Contact-container .contact-form-submit .contact-form-submit-text {
  width: 893px;
  display: inline-block;
  height: 56px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 28px;
  text-align: center;
  transition: all 0.3s;
  position: relative;
  text-decoration: none;
  padding: 18px 45px;
}

.Contact-container .contact-form-submit .contact-form-submit-text:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  color: #ff004f;
}

.Contact-container .contact-form-submit .contact-form-submit-text::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 28px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.Contact-container
  .contact-form-submit
  .contact-form-submit-text:hover::before {
  opacity: 1;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .Contact-container {
    padding-top: 50px;
  }

  .Contact-container .desc {
    margin: auto;
    margin-top: 15px;
    width: 95%;
  }

  .Contact-container .contact-form-input label {
    margin-bottom: 10px;
  }

  .Contact-container .contact-form-input {
    width: 350px;
    margin-top: 28px;
  }
  .Contact-container .contact-form-submit {
    width: 350px;
    margin-top: 35px;
  }
  .Contact-container .contact-form-submit .contact-form-submit-text {
    width: 350px;
  }
  /* *{
    box-shadow: none!important;
  } */
}
/* Mobile END*/

.Pricing-container {
  padding-top: 95px;
}

.Pricing-container .desc {
  margin-top: 22px;
}
.Pricing-container .para {
  width: 860px;
}

.Pricing-container .custom-tabs {
  flex-direction: column;
  margin-top: 47px;
}

.Pricing-container .custom-tabs .nav-tabs {
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  position: relative;
  border: none;
}

.Pricing-container .custom-tabs .nav-tabs .nav-item .nav-link {
  width: 275px;
  text-decoration: none;
  color: #fff;
  height: 93px;
  transition: all 0.3s;
  border: none;
}

.Pricing-container .custom-tabs .nav-tabs .nav-item .nav-link:hover {
  border: none;
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.Pricing-container .custom-tabs .nav-tabs .nav-item .nav-link.active {
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  color: #ff004f;
  border: none;
}

.Pricing-container .custom-tabs .nav-tabs .nav-item .nav-link::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.Pricing-container .custom-tabs .nav-tabs .nav-item .nav-link:hover::before {
  opacity: 1;
}

.Pricing-container .custom-tabs .pricing-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 550px;
  margin-top: 41px;
}

.Pricing-container .pricing-form-input {
  margin-top: 22px;
}

.Pricing-container .pricing-form-input .input-hourstobook,
.Pricing-container .pricing-form-input .input-ethtokenstobuy,
.Pricing-container .pricing-form-input .input-blazetokenstobuy {
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  border: 0;
  color: #fff;
  padding-left: 13px;
  width: 399px;
  margin-right: 31px;
  height: 56px;
}

.Pricing-container .pricing-form-input .input-hourstobook:focus-visible,
.Pricing-container .pricing-form-input .input-ethtokenstobuy:focus-visible,
.Pricing-container .pricing-form-input .input-blazetokenstobuy:focus-visible {
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  outline-width: 0;
}

.Pricing-container .custom-tabs .pricing-form .pricing-form-submit {
  width: 100%;
  margin-top: 25px;
}
.Pricing-container .custom-tabs .pricing-form .pricing-form-submit button {
  width: 100%;
  height: 56px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 28px;
  text-align: center;
  transition: all 0.3s;
  position: relative;
  text-decoration: none;
  border: none;
}
.Pricing-container
  .custom-tabs
  .pricing-form
  .pricing-form-submit
  button:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  color: #ff004f;
}

.Pricing-container
  .custom-tabs
  .pricing-form
  .pricing-form-submit
  button::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 28px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.Pricing-container
  .custom-tabs
  .pricing-form
  .pricing-form-submit
  button:hover::before {
  opacity: 1;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .Pricing-container {
    padding-top: 50px;
  }

  .Pricing-container .para {
    width: 350px;
    margin-top: 15px;
  }

  .Pricing-container .para p {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .Pricing-container .custom-tabs {
    margin-top: 25px;
  }

  .Pricing-container .custom-tabs .nav-tabs {
    /* width: 98%; */
  }

  .Pricing-container .custom-tabs .nav-tabs .nav-item .nav-link {
    width: 180px;
    font-size: 14px;
  }

  .Pricing-container .custom-tabs .pricing-form {
    width: 100%;
  }
  .Pricing-container .pricing-form-input {
    display: flex;
    align-items: center;
  }

  .Pricing-container .pricing-form-input .input-hourstobook,
  .Pricing-container .pricing-form-input .input-ethtokenstobuy,
  .Pricing-container .pricing-form-input .input-blazetokenstobuy {
    width: 250px;
    margin-right: 10px;
  }

  .Pricing-container .pricing-form-input img {
    width: 50px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .Pricing-container .desc:not(.para) p {
    margin-bottom: 0;
  }
}
/* Mobile END*/

.Footer-container {
  margin-top: 115px;
  padding-bottom: 107px;
}

.Footer-container .subTitle {
  margin-top: 14px;
  font-size: 20px;
}

.Footer-container .desc {
  margin-top: 14px;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .Footer-container {
    margin-top: 60px;
    padding-bottom: 55px;
  }
  .Footer-container .desc {
    margin-top: 7px;
  }
}
/* Mobile END */

.Menubar-container {
  margin-bottom: 60px;
}

.Menubar-container .navbar-main.show-navbar.background-dark{
    box-shadow: 0px 0px 19px 0 #1c1e22;
    background-color: #212428de;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
}

.Menubar-container > .navbar-main {
  height: 100px;
}
.Menubar-container > .navbar-main > .container {
  max-width: 1500px;
}


.Menubar-container .navbar-collapse .navbar-nav {
  align-items: center;
}

.Menubar-container .menubar-link {
  color: white !important;
  padding-left: 0 !important;
  padding-right: 0px !important;
  margin-right: 35px !important;
}

.Menubar-container .menubar-link:hover {
  color: #ff004f !important;
}

.Menubar-container .menubar-link.connect-wallet-button {
  color: #ff004f !important;
  margin-right: 0 !important;
  height: 56px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 28px;
  text-align: center;
  transition: all 0.3s;
  position: relative;
  text-decoration: none;
  padding: 18px 33px !important;
}

.Menubar-container .menubar-link.connect-wallet-button:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.Menubar-container .menubar-link.connect-wallet-button::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 28px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.Menubar-container .menubar-link.connect-wallet-button:hover::before {
  opacity: 1;
}

.bn-onboard-custom.bn-onboard-modal.svelte-rntogh{
  z-index: 9;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .Menubar-container > .navbar-main {
    height: 66px;
    z-index: 999999;
  }
  .navbar-collapse {
    box-shadow: 0px 0px 19px 0 #1c1e22;
    background-color: #212428f2;
  }
  .Menubar-container .menubar-link {
    margin-right: 0 !important;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .Menubar-container .navbar-collapse .navbar-nav {
    padding-bottom: 30px;
  }
}
/* Mobile END */
.ring {
    position: fixed;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border: 2px solid #FF004F;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* -webkit-transition-duration: 1ms; */
    /* transition-duration: 1ms; */
    transition-timing-function: ease-out;
    will-change: width, height, transform, border;
    z-index: 999999;
    pointer-events: none;
  }
  
  /* .dot {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: #FF004F;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    z-index: 999;
    pointer-events: none;
  } */

  /* Mobile */
@media screen and (max-width: 1099px) {
  .ring{
    display: none;
  }
}
  /* Mobile END */

.AccountNavbar .navbar-main {
  box-shadow: 0px 0px 19px 0 #1c1e22;
  background-color: #212428de;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  padding: 25px 0;
}

.AccountNavbar .bottombar-text {
  font-size: 15px;
}

.AccountNavbar .navbar-main .navbar-collapse.collapse {
  justify-content: end;
}

.AccountNavbar .bottombar-details-right {
  margin-right: 70px;
  text-align: center;
}

.AccountNavbar .navbar-main .button-secondary2 {
  height: 56px;
  width: 200px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 28px;
  text-align: center;
  transition: all 0.3s;
  position: relative;
  text-decoration: none;
  padding: 18px 33px !important;
}

.AccountNavbar .navbar-main .button-secondary2:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}

.AccountNavbar .navbar-main .button-secondary2::before {
  background: linear-gradient(to right bottom, #212428, #16181c);
  width: 100%;
  height: 100%;
  border-radius: 28px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  content: "";
}

.AccountNavbar .navbar-main .button-secondary2 a {
  color: #ff004f !important;
  text-decoration: none;
}

.AccountNavbar .navbar-collapse .navbar-nav.navbar-nav-scroll {
  align-items: center;
}

.AccountNavbar .account-avatar {
  margin-right: 10px;
}

/* Mobile Start */
@media only screen and (max-width: 1099px) {
  .AccountNavbar .navbar-collapse {
    margin-top: 10px;
    box-shadow: none;
    background-color: transparent;
    /* text-align: center; */
  }
  .AccountNavbar .bottombar-details-right {
    margin-bottom: 35px;
    margin-right: 0;
  }
  .AccountNavbar .navbar-light .navbar-toggler-icon {
    /* background-image: url('../../img/common/HamburgerDark.svg'); */
  }

  .AccountNavbar .navbar-main .button-secondary2 {
      margin-bottom: 30px;
  }

  .AccountNavbar .navbar-main {
      padding-bottom: 0;
  }
}
/* Mobile END */

