.Clients-container {
  padding-top: 95px;
}

.Clients-container .row-custom.cards {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.Clients-container .card {
  display: flex;
  justify-content: center;
  width: 187px;
  height: 187px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  border-radius: 5px;
  text-align: center;
  position: relative;
  margin-top: 50px;
  align-items: center;
}

/* .Clients-container .card .card-image img{
    width: 101px;
} */

/* Mobile */
@media screen and (max-width: 1099px) {
  .Clients-container {
    padding-top: 50px;
  }

  .Clients-container .row-custom.cards {
    justify-content: space-evenly;
    margin-top: 0;
  }

  .Clients-container .card {
  margin-top: 25px;
  width: 80px;
  height: 80px;
  }

  .Clients-container .card img{
    max-width: 50px;
    max-height: 50px;
  }
}
/* Mobile END */