.row-custom.custom-tab {
  flex-direction: column;
}
.row-custom.custom-tab .heading {
  font-size: 25px;
  font-weight: 600;
  margin-top: 73px;
}

.row-custom.custom-tab .col-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row-custom.custom-tab .custom-icon {
  width: 209px;
  margin-top: 50px;
}

.row-custom.custom-tab .custom-icon .custom-icon-title {
  margin-top: 22px;
}

/* Mobile */
@media screen and (max-width: 1099px) {
  .row-custom.custom-tab .heading {
    margin-top: 50px;
  }
  .row-custom.custom-tab .col-icons {
    justify-content: space-evenly;
  }
  .row-custom.custom-tab .custom-icon {
    width: 80px;
    margin-top: 25px;
  }
  
  .row-custom.custom-tab .custom-icon .custom-icon-image{
    height: 70px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .row-custom.custom-tab .custom-icon .custom-icon-image img{
    max-width: 70px;
  }
  .row-custom.custom-tab .custom-icon .custom-icon-title {
    font-size: 12px;
  }
}
/* Mobile END */